import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { AuthService } from './auth.service';
import { environment } from '../../environments/environment.prod';

@Injectable({
  providedIn: 'root'
})
export class ActivityService {
  time = environment.inactivityTimeout

  lastActivity: number = Date.now()
  working: boolean = false

  constructor(private router: Router, private auth: AuthService) { }

  async updateActivity() {
    if (this.working) return
    this.working = true
    const newDate = Date.now()
    if ((newDate - this.lastActivity) > this.time) {
      await this.router.navigate(['auth/login'], {replaceUrl: true})
      await this.auth.signOut()
    } else this.lastActivity = newDate

    this.working = false
  }

  reset() {
    this.lastActivity = Date.now()
  }
}
