import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { debounceTime, delay, map, Observable, of, tap } from 'rxjs';
import { environment } from '../../environments/environment.pre';
import { SupplierIn, SupplierInListWrapped, SupplierInWrapped } from '../models/suppliers/supplier-in';
import { SupplierOut } from '../models/suppliers/supplier-out';
import { SupplierResp } from '../models/suppliers/supplier-resp';

@Injectable({
  providedIn: 'root'
})
export class SuppliersService {
  baseUrl = environment.apiUrl + "businesses/suppliers"


  suppliers: SupplierIn[] = []
  hasNewSupplier: Boolean = false
  hasUpdatedSupplier: Boolean = false
  loaded: Boolean = false
  updateTarget: SupplierIn | null = null
  fetchedTarget: SupplierResp | null = null

  totalResults = 0
  resultsPerPage = 10

  constructor(private http: HttpClient) { }

  stopAll() {
    this.suppliers = []
    this.hasNewSupplier = false
    this.hasUpdatedSupplier = false
    this.loaded = false
    this.updateTarget = null
    this.fetchedTarget = null
  }

  listSuppliers(page: number = 1): Observable<any> {
    let existing = this.suppliers.filter(it => it.pageNumber == page)
    if (0 < existing.length) {
      if (existing.length < this.resultsPerPage) this.addSuppliers(page).subscribe(_ => { })
      return of(existing)
    }

    this.loaded = false
    return this.addSuppliers(page)
  }

  async getBulk(ids: string[]) {
    const fetchedIds = this.suppliers.filter(e => e.withdrawal_methods!!).map(e => e.id)
    let idsToFetch = ids.filter(it => fetchedIds.indexOf(it) < 0)
    if (idsToFetch.length == 0) return true


    await this.http.get<SupplierInListWrapped>(`${this.baseUrl}/show_in_bulk?ids=${idsToFetch.join(',')}`)
      .pipe(
        tap(result => {
          result.suppliers.forEach((s => {
            try {
              this.suppliers.find(it => it.id == s.id)!!.withdrawal_methods = s.withdrawal_methods
            } catch(_) {
              this.suppliers.push(new SupplierIn(s))
            }
          }))
        })
      ).toPromise()

      return true
  }

  addSuppliers(page: number = 1) {
    const delayMillis = this.hasNewSupplier && page == 1 ? 5000 : 0
    return this.http.get<SupplierInListWrapped>(`${this.baseUrl}?per_page=${this.resultsPerPage}&page=${page}`)
      .pipe(
        delay(delayMillis),
        tap(result => {
          this.totalResults = result.suppliers_count
          this.suppliers = this.loaded ? this.suppliers : this.suppliers.concat(result.suppliers.map(it => new SupplierIn(it, result.page)))
          this.hasNewSupplier = false
          this.loaded = true
        }),
      )
  }

  filterSuppliers(name: string = '') {
    return this.http.get<SupplierInListWrapped>(`${this.baseUrl}?per_page=${this.resultsPerPage}&name=${name}`)
      .pipe(
        debounceTime(300),
        tap(result => {
          this.totalResults = result.suppliers_count
          const uniques: any = {}
          this.suppliers.concat(result.suppliers.map(it => new SupplierIn(it, result.page))).forEach(e => {
            uniques[e.id] = e
          })
          this.suppliers = Object.values(uniques)
          this.hasNewSupplier = false
          this.loaded = true
        }),
      )
  }

  addSupplier(supplierObj: SupplierOut) {
    return this.http.post(this.baseUrl, { supplier: supplierObj })
      .pipe(
        tap((supplierAdded: any) => {
          if (null == supplierAdded) {
            console.log("We failed adding a supplier!")
          }
          else {
            console.log("Supplier added successfully!")
            this.suppliers.push(new SupplierIn(supplierAdded.supplier!!))
            this.hasNewSupplier = true
          }
        })
      )
  }

  updateEmployee(supplierObj: SupplierOut, id: string) {
    return this.http.patch(this.baseUrl + "/" + id, { supplier: supplierObj })
      .pipe(
        tap((supplierAdded: any) => {
          if (null == supplierAdded) {
            console.log("We failed updating a supplier!")
          }
          else {
            let updated = this.suppliers.filter(supplierOld => supplierOld.id == supplierAdded.supplier!!.id)[0]
            Object.assign(updated, supplierAdded.supplier)
            this.hasUpdatedSupplier = true
          }
        })
      )
  }

  getSupplier(id: string): Observable<SupplierResp> {
    return this.http.get<SupplierResp>(`${this.baseUrl}/${id}`).pipe(
      map((resp: any) => {
        return resp.supplier as SupplierResp
      })
    )
  }

  resetSelection() {
    this.suppliers.forEach(e => e.selected = false)
  }
  resetList() {
    this.suppliers = []
  }
}
