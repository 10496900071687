import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, tap } from 'rxjs';
import { environment } from '../../environments/environment.prod';
import { ProductIn, ProductListIn } from '../models/product-in';

@Injectable({
  providedIn: 'root'
})
export class ProductsService {
  baseURL = `${environment.apiUrl}businesses/products`;
  list: ProductIn[] = []

  constructor(private http: HttpClient) { }

  stopAll() {
    this.list = []
  }

  loadProducts() {
    return this.http.get<ProductListIn>(this.baseURL).pipe(
      tap(result => {
        this.list = result.products.map ((it: any) => ProductIn.fromWire(it))
      })
    )
  }
}
