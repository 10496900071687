@if (showAlert) {
<div [style.position]="position" class="z-50 m-3">
  <div [ngClass]="{
    'alert-info border': alertType === 'info',
    'border alert-success': alertType === 'success',
    'alert-error border': alertType === 'error',
    }" class=" px-10 py-3 rounded relative" role="alert">
    <strong class="font-bold mr-2">{{alertTitle}}</strong>
    <span class="block sm:inline">{{alertMessage}}</span>
    <span (click)="onClose()" class="absolute top-0 bottom-0 right-0 px-4 py-3">
      <svg [ngClass]="{
        'alert-info': alertType === 'info',
        'alert-success': alertType === 'success',
        'alert-error': alertType === 'error'
        }" class="fill-current h-6 w-6" role="button" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20">
        <title>Close</title>
        <path
          d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
      </svg>
    </span>
  </div>
</div>
}
