interface String {
    symbols(): string
}
String.prototype.symbols = function (): string {
    const parts = this.split(" ")
    if (0 == parts.length) return ""
    if (1 == parts.length) return  parts[0].charAt(0).toUpperCase()
    if (2 == parts.length) return parts[0].charAt(0).toUpperCase() + parts[1].charAt(0).toUpperCase()
    else return parts[0].charAt(0).toUpperCase() + parts[parts.length - 1].charAt(0).toUpperCase()
}
