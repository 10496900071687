import { WithdrawalMethodIn } from "../withdrawal-method/withdrawal-method-in"
import "../../extensions/string-symbols"
import { MobileMoneyOut } from "../withdrawal-method/mobile-money-out"
import { BankAccountOut } from "../withdrawal-method/bank-account-out"
import { SharedService } from "../../services/shared.service"
import { getEnabledCategories } from "trace_events"

export class SupplierIn {
    id = ""
    legal_name = ""
    reference = ""
    type_id = ""
    category_id = ""

    last_name = ""
    main_withdrawal_method = new WithdrawalMethodIn
    withdrawal_methods: any | null = null
    phone: string = ""
    email: string = ""
    color = ""
    selected: boolean = false
    pageNumber = 1

    type(types: any[] = []) {
        return !this.type_id || types.length == 0 ? '-' : types.find(it => it.id == this.type_id).name
    }
    category(categories: any[] = []) {
        return !this.category_id || categories.length == 0 ? '-' : categories.find(it => it.id == this.category_id).name
    }

    constructor(item: SupplierIn, page: number = 1) {
        Object.assign(this, {...item, pageNumber: page})
        const colorOptions = ["bg-blue-500", "bg-green-500", "bg-purple-500", "bg-red-500"]
        this.color = colorOptions[Math.floor(Math.random() * 50) % colorOptions.length]
    }

    get accounts(): any[] {
        const result: any[] = []
        if (!this.withdrawal_methods) return result
        if (this.withdrawal_methods.mobile_money) result.push(this.withdrawal_methods.mobile_money)
        if (this.withdrawal_methods.bank_account) result.push(this.withdrawal_methods.bank_account)
        if (this.withdrawal_methods.futa_wallet) result.push(this.withdrawal_methods.futa_wallet)
        return result
    }

    fullName(): String {
        return this.legal_name + " " + this.last_name
    }

    symbols(): string {
        return this.fullName().symbols()
    }
}

export class SupplierInWrapped {
    supplier:SupplierIn|null = null
}

export class SupplierInListWrapped {
    page: number = 1
    suppliers_count: number = 0
    suppliers: SupplierIn[] = []
}
