@if (forAuth) {
<div class="fixed inset-0 flex items-center justify-center bg-gray-900 bg-opacity-50 z-50">
  <div class="animate-spin rounded-full h-16 w-16 border-t-2 border-b-2 border-gray-100"></div>
</div>
} @else {
<div class="flex-col gap-4 w-full flex items-center justify-center">
  <div class="w-28 h-28 border-8 text-4xl animate-spin spinner-border flex items-center justify-center rounded-full">
    <img src="../../../assets/futa-icon.png" alt="">
  </div>
</div>
}
