import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from '../../environments/environment.pre';
import { map, Observable } from 'rxjs';
import { StaticData } from '../models/static-data';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class SharedService {
  baseURL = `${environment.apiUrl}businesses`;
  staticData: any = {};
  loaded = false
  defaultLang: string = ''

  constructor(private http: HttpClient, private translate: TranslateService) {
    this.defaultLang = this.translate.getDefaultLang();
  }

  operatorIdToFullName(id: string): string {
    const result = this.staticData.mobile_money_operators.find((it: any) => it.id == id)?.name
    return result ? result : id
  }
  bankIdToFullName(id: string): string {
    const result = this.staticData.bank_operators.find((it: any) => it.id == id)?.name
    return result ? result : id
  }
  accountIdToName(id: string): string {
    return this.operatorIdToFullName(id) != id  ? this.operatorIdToFullName(id) : this.bankIdToFullName(id)
  }
  accountName(account: any) {
    if (account.type == "MOBILE_MONEY") return this.operatorIdToFullName(account.operator_id)
    if (account.type == "BANK_ACCOUNT") return this.bankIdToFullName(account.operator_id)
    if (account.type == "FUTA_WALLET") return "Futa Wallet"
    return "N/A"
  }

  categoryName(categoryId: string): string {
    const result = this.staticData.business_categories?.find((it: any) => it.id == categoryId)?.name
    return result ? result : categoryId
  }
  statusName(statusId: string): string {
    const result = this.staticData.business_legal_statuses?.find((it: any) => it.id == statusId)?.name
    return result ? result : statusId
  }

  getStaticData(): Observable<StaticData> {
    return this.http.get<StaticData>(`${this.baseURL}/static_data`, { headers: { 'Accept-Language': this.defaultLang } }).pipe(
      map(result => {
        this.staticData = result;
        this.loaded = true
        return result;
      })
    );
  }

  getPublicStaticData(): Observable<StaticData> {
    return this.http.get<StaticData>(`${this.baseURL}/static_data/show_public`, { headers: { 'Accept-Language': this.defaultLang } }).pipe(
      map(result => {
        this.staticData = result;
        this.loaded = true
        return result;
      })
    );
  }

  getMobileMoneyOperator(eventValue: string): string {
    const mtnRegex = /^(?:\+237)?(?:67|65[0-4]|68[0-3])/;
    const orangeRegex = /^(?:\+237)?(?:69|65[5-9])/;

    if (mtnRegex.test(eventValue)) {
      return 'mtn_mobile_money';
    } else if (orangeRegex.test(eventValue)) {
      return 'orange_money';
    }

    return 'unknown';
  }
}
