import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { debounceTime, delay, map, Observable, of, switchMap, tap } from 'rxjs';
import { environment } from '../../environments/environment.pre';
import { EmployeeIn, EmployeeInListWrapped, EmployeeInWrapped } from '../models/employee/employee-in';
import { EmployeeOut } from '../models/employee/employee-out';
import { SupplierResp } from '../models/suppliers/supplier-resp';
import { EmployeeResp } from '../models/employee/employee-resp';

@Injectable({
  providedIn: 'root'
})
export class EmployeesService {
  baseUrl = environment.apiUrl + "businesses/employees"


  employees: EmployeeIn[] = []
  hasNewEmployee: Boolean = false
  hasUpdatedEmployee: Boolean = false
  loaded: Boolean = false
  updateTarget: EmployeeIn | null = null
  fetchedTarget: EmployeeResp | null = null

  totalResults = 0
  resultsPerPage = 10

  constructor(private http: HttpClient) { }

  stopAll() {
    this.employees = []
    this.hasNewEmployee = false
    this.hasUpdatedEmployee = false
    this.loaded = false
    this.updateTarget = null
    this.fetchedTarget = null
  }

  listEmployees(page: number = 1): Observable<any> {
    let existing = this.employees.filter(it => it.pageNumber == page)
    if (0 < existing.length) {
      if (existing.length < this.resultsPerPage) this.addEmployees(page).subscribe(_ => { })
      return of(existing)
    }

    this.loaded = false
    return this.addEmployees(page)
  }

  async getBulk(ids: string[]) {
    const fetchedIds = this.employees.filter(e => e.withdrawal_methods).map(e => e.id)
    let idsToFetch = ids.filter(it => fetchedIds.indexOf(it) < 0)
    if (idsToFetch.length == 0) return true


    await this.http.get<EmployeeInListWrapped>(`${this.baseUrl}/show_in_bulk?ids=${idsToFetch.join(',')}`)
      .pipe(
        tap(result => {
          result.employees.forEach((e => {
            try {
              this.employees.find(it => it.id == e.id)!!.withdrawal_methods = e.withdrawal_methods
            } catch(_) {
              this.employees.push(new EmployeeIn(e))
            }
          }))
        })
      ).toPromise()

      return true
  }

  filterEmployees(name: string = '') {
    return this.http.get<EmployeeInListWrapped>(`${this.baseUrl}?per_page=${this.resultsPerPage}&name=${name}`)
      .pipe(
        debounceTime(300),
        tap(result => {
          this.totalResults = result.employees_count
          const uniques: any = {}
          this.employees.concat(result.employees.map(it => new EmployeeIn(it, result.page))).forEach(e => {
            uniques[e.id] = e
          })
          this.employees = Object.values(uniques)
          this.hasNewEmployee = false
          this.loaded = true
        }),
      )
  }

  addEmployees(page: number = 1) {
    const delayMillis = this.hasNewEmployee && page == 1 ? 5000 : 0
    return this.http.get<EmployeeInListWrapped>(`${this.baseUrl}?per_page=${this.resultsPerPage}&page=${page}`)
      .pipe(
        delay(delayMillis),
        tap(result => {
          this.totalResults = result.employees_count
          this.employees = this.loaded ? this.employees : this.employees.concat(result.employees.map(it => new EmployeeIn(it, result.page)))
          this.hasNewEmployee = false
          this.loaded = true
        }),
      )
  }

  addEmployee(employee: EmployeeOut) {
    return this.http.post<EmployeeInWrapped>(this.baseUrl, employee.wrap())
      .pipe(
        tap((employeeAdded: EmployeeInWrapped) => {
          if (null == employeeAdded) {
            console.log("We failed adding an employee!")
          }
          else {
            console.log("Employee added successfully!")
            this.employees.push(new EmployeeIn(employeeAdded.employee!!))
            this.hasNewEmployee = true
          }
        })
      )
  }

  newEmployee(newEmployee: any) {
    return this.http.post(this.baseUrl, newEmployee).pipe(
      tap(_ => {
        this.employees.push(newEmployee)
        this.hasNewEmployee = true
      })
    )
  }

  updateEmployee(employee: EmployeeOut, id: string) {
    return this.http.patch<EmployeeInWrapped>(this.baseUrl + "/" + id, employee.wrap())
      .pipe(
        tap((employeeAdded: EmployeeInWrapped) => {
          if (null == employeeAdded) {
            console.log("We failed updating an employee!")
          }
          else {
            console.log("Employee updated successfully!")
            let updated = this.employees.filter(employeeOld => employeeOld.id == employeeAdded.employee!!.id)[0]
            Object.assign(updated, employeeAdded.employee)
            this.hasUpdatedEmployee = true
          }
        })
      )
  }

  updateEmployee2(employee: any, id: string) {
    return this.http.patch(this.baseUrl + "/" + id, employee)
      .pipe(
        tap((employeeAdded: any) => {
          if (null == employeeAdded) {
            console.log("We failed updating an employee!")
          }
          else {
            let updated = this.employees.filter(employeeOld => employeeOld.id == employeeAdded.employee!!.id)[0]
            Object.assign(updated, employeeAdded.employee)
            this.hasUpdatedEmployee = true
          }
        })
      )
  }

  getEmployee(id: string): Observable<EmployeeResp> {
    return this.http.get<EmployeeResp>(`${this.baseUrl}/${id}`).pipe(
      map((resp: any) => {
        return resp.employee as EmployeeResp
      })
    )
  }

  resetSelection() {
    this.employees.forEach(e => e.selected = false)
  }
  resetList() {
    this.employees = []
  }
}
