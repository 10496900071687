import { HttpClient } from '@angular/common/http';
import { Injectable, computed, effect, signal } from '@angular/core';
import { TokenService } from './token.service';
import { IInitAccountVerification, ILogin, ILoginResponse, IVerifyAccount } from '../models/auth.model';
import { environment } from '../../environments/environment.pre';
import { map } from 'rxjs';
import { DashboardService } from './dashboard.service';
import { EmployeesService } from './employees.service';
import { ProductsService } from './products.service';
import { SuppliersService } from './suppliers.service';

type AuthState = {
  user: { firstname: string } | null;
  token: string | null;
  is_auth: boolean;
}

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  apiURL = environment.apiUrl;

  constructor(
    private http: HttpClient,
    private dahboard: DashboardService,
    private employees: EmployeesService,
    private products: ProductsService,
    private suppliers: SuppliersService,
    private tokenService: TokenService
    ) {

  }

  signup(data: { business: any }) {
    return this.http
      .post<any>(`${this.apiURL}businesses/auth/signup`, data)
  }

  login(data: { auth: ILogin }) {
    return this.http
      .post<ILoginResponse>(`${this.apiURL}businesses/auth/signin`, data)
      .pipe(
        map((response: ILoginResponse) => {
          if (response) {
            this.tokenService.setToken(response.access_token.token);
            this.tokenService.setPassHash(data.auth.password)
            localStorage.setItem('firstname', response.user.firstname);
          }
          return response;
        })
      )
  }

  initAccountVerification(data: { auth: IInitAccountVerification }) {
    return this.http
      .post(`${this.apiURL}businesses/auth/init_account_verification`, data)
      .pipe(
        map((response) => {

          return response;
        })
      )
  }

  verifyAccount(data: { auth: IVerifyAccount }) {
    return this.http
      .post<any>(`${this.apiURL}businesses/auth/verify_account`, data)
      .pipe(
        map((response) => {
          this.tokenService.setToken(response.token);
          return response;
        })
      )
  }

  updatePassword(password: string) {
    return this.http
      .patch(`${this.apiURL}v1/me/update_password`, { password })
      .pipe(
        map((response) => {
          return response;
        })
      )
  }

  sendOTP(data: { auth: { phone_number: string, phone_country: string } }) {
    return this.http
      .post(`${this.apiURL}v1/auth/send_phone_otp`, data)
      .pipe(
        map((response) => {
          return response;
        })
      )
  }

  async signOut() {
    this.tokenService.removeToken();
    localStorage.removeItem('firstname');
    // Stop all the dashboard services...
    this.dahboard.stopAll()
    this.employees.stopAll()
    this.products.stopAll()
    this.suppliers.stopAll()
  }
}
