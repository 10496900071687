import { WithdrawalMethodIn } from "../withdrawal-method/withdrawal-method-in"
import "../../extensions/string-symbols"
import { randomInt } from "crypto"

export class EmployeeIn {
    static colorOptions = ["bg-blue-500", "bg-green-500", "bg-purple-500", "bg-red-500"]
    static nextColor = 0
    id = ""
    first_name = ""
    last_name = ""
    reference = ""
    employment_type_id = ""
    role = ""

    withdrawal_methods: any|null = null


    main_withdrawal_method = new WithdrawalMethodIn()
    color = ""
    selected: boolean = false
    pageNumber = 1

    constructor(item: EmployeeIn, page: number = 1) {
        Object.assign(this, {...item, pageNumber: page})
        this.color = "" != this.color ? this.color : EmployeeIn.colorOptions[EmployeeIn.nextColor % EmployeeIn.colorOptions.length]
        EmployeeIn.nextColor ++
    }

    employmentType(employmentTypes: any [] = []) {
        return !this.employment_type_id || employmentTypes.length == 0 ? '-' : employmentTypes.find(it => it.id == this.employment_type_id).name
    }

    get accounts(): any[] {
        const result: any[] = []
        if (!this.withdrawal_methods) return result
        if (this.withdrawal_methods.mobile_money) result.push(this.withdrawal_methods.mobile_money)
        if (this.withdrawal_methods.bank_account) result.push(this.withdrawal_methods.bank_account)
        if (this.withdrawal_methods.futa_wallet) result.push(this.withdrawal_methods.futa_wallet)
        return result
    }

    get fullName(): String {
        return this.first_name + " " + this.last_name
    }

    get roleName(): string {
        return !this.role ? '-' : this.role
    }

    symbols(): string {
        return this.fullName.symbols()
    }
}

export class EmployeeInWrapped {
    employee: EmployeeIn|null = null
}

export class EmployeeInListWrapped {
    page: number = 1
    employees_count: number = 0
    employees: EmployeeIn[] = []
}
