import { OperatorIn } from "./operator-in"

export class ProductIn {
    id: string = ""
    title: string = ""
    type: string = ""
    description: string = ""
    payerOperator: OperatorIn = new OperatorIn
    payableOperator: OperatorIn = new OperatorIn

    static fromWire(input: any): ProductIn {
        return Object.assign(new ProductIn, {
            id: input.id, 
            title: input.title, 
            type: input.type, 
            description: input.description, 
            payerOperator: OperatorIn.fromWire(input.payable_operator),
            payableOperator: OperatorIn.fromWire(input.operator)
        })
    }
}

export class ProductListIn {
    products: ProductIn[] = []
}
