import { CommonModule } from '@angular/common';
import { Component, Input, output } from '@angular/core';

@Component({
  selector: 'app-alert',
  standalone: true,
  imports: [CommonModule],
  templateUrl: './alert.component.html',
  styleUrl: './alert.component.scss'
})
export class AlertComponent {
  @Input() alertTitle: string = 'Alert';
  @Input() alertMessage: string = 'This is an alert message.';
  @Input() showAlert: boolean = true;
  @Input() alertDuration: number = 3000;
  @Input() alertType: string = 'info';
  /* Possible values for position: fixed, absolute, relative, sticky */
  @Input() position: string = 'fixed';

  constructor() {
    setTimeout(() => {
      this.showAlert = false
    }, this.alertDuration);
  }

  onClose() {
    this.showAlert = false;
  }

}
