import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { filter, Observable, of, switchMap, take, takeWhile, tap, timer } from 'rxjs';
import { environment } from '../../environments/environment.pre';

@Injectable({
  providedIn: 'root'
})
export class DashboardService {
  baseURL = `${environment.apiUrl}businesses/dashboard`;

  dashboard: any = {
    wallet_balance: {value: 0, currency: 'XAF'},
    last_transactions: [],
    next_schedule: {total_amount: {value: 0, currency: 'XAF'}},
    last_employee_paid: [],
    employee_total_salary_paid: {value: 0, currency: 'XAF'},
    supplier_total_paid: {value: 0, currency: 'XAF'}
  }
  continuous: boolean = true

  constructor(private http: HttpClient) { }

  summary(): Observable<any> {
    if (localStorage.getItem('firstname') == null) return of(this.dashboard)
    return this.http.get(this.baseURL).pipe(
      tap(result => this.dashboard = result)
    )
  }

  checkSummary(): Observable<any> {
    return timer(0, 10000).pipe(
      switchMap(() => this.summary()),
      takeWhile(() => this.continuous)
    )
  }

  stopAll() {
    this.dashboard = {
      wallet_balance: {value: 0, currency: 'XAF'},
      last_transactions: [],
      next_schedule: {total_amount: {value: 0, currency: 'XAF'}},
      last_employee_paid: [],
      employee_total_salary_paid: {value: 0, currency: 'XAF'},
      supplier_total_paid: {value: 0, currency: 'XAF'}
    }
    this.continuous = false
  }
}
