import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import * as bcrypt from 'bcryptjs'

@Injectable({
  providedIn: 'root'
})
export class TokenService {
  isAuthenticated: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  PASSHASH_KEY = 'password_hash';
  TOKEN_KEY = 'token';

  constructor() {
    this.isAuthenticated.next(!!localStorage.getItem(this.TOKEN_KEY));
  }

  checkPassword(password: string) {
    return bcrypt.compareSync(password, this.getPassHash()!!)
  }

  updateToken(status: boolean) {
    this.isAuthenticated.next(status);
  }

  setToken(token: string) {
    this.isAuthenticated.next(true);
    localStorage
      .setItem(this.TOKEN_KEY, token);
  }
  setPassHash(pass: string) {
    const hashed = bcrypt.hashSync(pass, 12)
    localStorage.setItem(this.PASSHASH_KEY, hashed)
  }

  getToken() {
    return localStorage.getItem(this.TOKEN_KEY) || null;
  }
  getPassHash() {
    return localStorage.getItem(this.PASSHASH_KEY) || null;
  }

  removeToken() {
    this.isAuthenticated.next(false);
    localStorage.removeItem(this.TOKEN_KEY);
  }
}
